// Credit for show/hide button: https://www.youtube.com/watch?v=Oj0m4dT7eY0
// Credit for show/hide button: https://react.dev/reference/react/useState

import React, { useState, useEffect, ChangeEvent } from 'react';
import AddNote from './components/AddNote';
import NoteList from './components/NoteList';
import { BackgroundImage } from './types/BackgroundImage';
import { ColorScheme } from './types/ColorScheme';

import image2 from './images/image2.jpeg';
import image3 from './images/image3.jpeg';
import image4 from './images/image4.jpeg';

const colorSchemeOption: ColorScheme[] = [
  {
    name: 'White',
    colorCode: '#FFFFFF',
  },
  {
    name: 'Green',
    colorCode: '#AFE1AF',
  },
  {
    name: 'Blue',
    colorCode: '#A7C7E7',
  },
  {
    name: 'Peach',
    colorCode: '#FFE5B4',
  },
];
// const imageOption: string[] = ['image1.jpg', 'image2.jpg', 'image3.jpg', 'image4.jpg'];

const imageOption: BackgroundImage[] = [
  {
    name: 'Default',
    url: 'none',
  },
  {
    name: 'Beach',
    url: image2,
  },
  {
    name: 'Galaxy',
    url: image3,
  },
  {
    name: 'Forest',
    url: image4,
  },
];

const App: React.FC = () => {
  const [colorScheme, setColorScheme] = useState(
    localStorage.getItem('colorScheme') || 'White',
  );
  const [backgroundImage, setBackgroundImage] = useState(
    localStorage.getItem('backgroundImage') || 'none',
  );
  const [showInstructions, setShowInstructions] = useState(true);

  // Function to toggle the visibility of the instructions
  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  useEffect(() => {
    localStorage.setItem('colorScheme', colorScheme);
    localStorage.setItem('backgroundImage', backgroundImage);
  }, [colorScheme, backgroundImage]);

  const imageChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setBackgroundImage(e.target.value);
    console.log(backgroundImage);
  };

  const colorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setColorScheme(e.target.value);
    console.log(colorScheme);
  };

  return (
    <div
      className='App'
      style={{
        backgroundImage:
          backgroundImage !== 'none' ? `url(${backgroundImage})` : 'none',
      }}
    >
      <div
        className='content-space'
        style={{
          backgroundColor: `rgba(${parseInt(colorScheme.slice(1, 3), 16)}, ${parseInt(colorScheme.slice(3, 5), 16)}, ${parseInt(colorScheme.slice(5, 7), 16)}, 0.8)`,
        }}
      >
        <h1>TODO Notes Application</h1>
        <h2>{new Date().toLocaleDateString()}</h2>
        <button onClick={toggleInstructions}>
          {showInstructions ? 'Hide Instructions' : 'Show Instructions'}
        </button>
        {/* Conditionally render the instructions */}
        {showInstructions && (
          <p className='instructions'>
            Welcome to the To-Do List App! Here&apos;s how to use it:
            <ul>
              <li>Type your task in the input field.</li>
              <li>Click &lsquo;Add note&rsquo; to add it to your TODO list.</li>
              <li>
                The note will be displayed below. Notes cannot be deleted.
              </li>
            </ul>
          </p>
        )}
        <div>
          <p>Background Image Picker:</p>
          <select value={backgroundImage} onChange={imageChange}>
            {imageOption.map((image: BackgroundImage, index: number) => (
              <option key={index} value={image.url}>
                {image.name}
              </option>
            ))}
          </select>

          <p>Color Scheme Picker:</p>
          <select value={colorScheme} onChange={colorChange}>
            {colorSchemeOption.map((color: ColorScheme, index: number) => (
              <option key={index} value={color.colorCode}>
                {color.name}
              </option>
            ))}
          </select>
        </div>
        <div className='Add-Note'>
          <AddNote />
        </div>
        <NoteList />
      </div>
    </div>
  );
};

// const App: React.FC = () => {
//   return (
//     <div className="App">
//       <h1>TODO Notes Application</h1>
//     </div>
//   );
// };

export default App;
