import React, { useEffect, useState } from 'react';
import { getAllNotes, updateNote } from '../services/noteService';
import { Note } from '../types/Note';

const NoteList: React.FC = () => {
  const [notes, setNotes] = useState<Note[]>([]),
    [loading, setLoading] = useState(true),
    [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const notes = await getAllNotes();
        console.log('Fetched notes:', notes); // Log the fetched notes
        if (Array.isArray(notes)) {
          setNotes(notes);
        } else {
          setError('Failed to fetch notes');
        }
      } catch (err) {
        console.error('Error fetching notes:', err);
        setError('Failed to fetch notes');
      } finally {
        setLoading(false);
      }
    };
    fetchNotes();
  }, []);

  const handleCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    note: Note,
  ) => {
    const update = async () => {
      try {
        const newNote = { ...note, completed: e.target.checked };
        // prettier-ignore
        const updateNotes = notes.map((n: Note) => (n.id === note.id ? newNote : n));
        setNotes(updateNotes);
        console.log(newNote);
        await updateNote(newNote);
      } catch (err) {
        console.error('Error when updating notes', err);
        setError('Failed to update notes');
      } finally {
        setLoading(false);
      }
    };

    update();
  };

  const completedNotes = notes.reduce(
    (count: number, note: Note) => (note.completed ? count + 1 : count),
    0,
  );
  const nonCompletedNotes = notes.length - completedNotes;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h2>All Notes</h2>
      <h3>
        Completed Items: {completedNotes}, Uncompleted Items:{' '}
        {nonCompletedNotes}
      </h3>
      <div>
        {notes.map((note: Note) => (
          <div key={note.id} className='note-container'>
            <input
              type='checkbox'
              checked={note.completed}
              onChange={(e) => handleCheckbox(e, note)}
            />
            <p>{note.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NoteList;
